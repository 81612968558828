$(function() {
  var scrollToAppendix = 2;

  if (window.location.pathname != '/') {
    setTimeout(function() {
      $('html, body').stop().animate({
        scrollTop: $('main').offset().top + scrollToAppendix - $('.fixed-header').outerHeight()
      }, 1500);
    }, 500);
  }

  $(".scroll-to-element").click(function() {
    var scrollToElement = $(this).attr('href');
    var scrollToElementHeading = $(scrollToElement).attr('aria-labelledby');
    var scrollTo = $('#' + scrollToElementHeading).offset().top

    $('html, body').stop().animate({
        scrollTop: scrollTo + scrollToAppendix - $('.fixed-header').outerHeight()
    }, 1500);
});
});
